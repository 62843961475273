import React from "react"
import { Container, Box, Grid, Paper } from "@material-ui/core";

import Layout from "../components/layout"
import SEO from "../components/seo"
import { PageTitle } from '../components/PageTitle'
import { styled } from '@material-ui/core/styles';

import bela from '../images/bela.png'
import escolaDeVestir from '../images/escola_do_vestir.png'
import romanni from '../images/romanni.png'
import abrada from '../images/abrada.png'
import ibnb from '../images/ibnb.png'

import {
  Instagram as InstagramIcon,
  Language as LanguageIcon,
} from '@material-ui/icons'

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  fill: '#FFF',
  height: 200,
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
  background: '#ded9d9',
  fontWeight: 500,
  padding: '20px'
}));

const BoxPartners = styled(Paper)(({ theme }) => ({
  p: 2,
  borderRadius: 2,
  display: 'grid',
  gridTemplateColumns: '1fr',
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: '1fr 1fr',
  },
  gap: '20px',
}));

const InstaIcon = styled(InstagramIcon)(({ theme }) => ({
  backgroundImage: 'linear-gradient(72.44deg, #FF7A00 11.92%, #FF0169 51.56%, #D300C5 85.69%)',
  color: '#fff', borderRadius: '14%'
}));

export default function DocumentsPage(props) {
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Layout>
      <SEO title="Parceiros" />
      <Container style={{ marginTop: 50 }}>
        <PageTitle>Parceiros</PageTitle>
        <Grid container style={{ marginTop: 50 }}>
          <Grid lg={12}>
            <BoxPartners
            >
              <Item key={13} elevation={13}>
                <img src={bela} width={120} height={120} />
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                  <p>Coraticum Copywriting LTDA - Formação em Copywriting</p>
                  <p>Escola de copywriting e estratégias de escrita e comunicação para formar quem deseja atuar profissionalmente como copywriter.</p>
                  <a
                    href="https://www.instagram.com/belafranceschini"
                    target="_blank"
                  >
                    <InstaIcon fontSize="medium" />
                  </a>
                </div>
              </Item>

              <Item key={13} elevation={13}>
                <img src={abrada} width={120} height={120} />
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                  <p>Academia Brasileira de Direito do Agronegócio - Abrada</p>
                  <p>Escola especializada em cursos na área do direito do agronegócio e agronegócio.</p>
                  <a
                    href="https://abradaoficial.com.br/"
                    target="_blank"
                  >
                    <LanguageIcon fontSize="medium" style={{ fill: '#434040' }} />
                  </a>
                </div>
              </Item>

              <Item key={13} elevation={13}>
                <img src={escolaDeVestir} width={120} height={120} />
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                  <p>Escola do Vestir - Estrategista de imagem e Coloração Pessoal</p>
                  <p>Formação em consultoria de imagem e coloração pessoal.</p>
                  <a
                    href="https://www.escoladovestir.com.br"
                    target="_blank"
                  >
                    <LanguageIcon fontSize="medium" style={{ fill: '#434040' }} />
                  </a>
                </div>
              </Item>

              <Item key={13} elevation={13}>
                <img src={romanni} width={120} height={120} />
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                  <p>Instituto Romanni - Psicologia e Negócios</p>
                  <p>Instituição parceira da faculdade jardins.</p>
                  <a
                    href="https://www.romanni.com.br/saiba-mais"
                    target="_blank"
                  >
                    <LanguageIcon fontSize="medium" style={{ fill: '#434040' }} />
                  </a>
                </div>
              </Item>
              <Item key={14} elevation={14}>
                <img src={ibnb} width={120} height={120} style={{ borderRadius: "50%" }} />
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                  <p>Instituto Brasileiro de Neurociências e Biohacking</p>
                  <p>Formação em Neurociências.</p>
                </div>
              </Item>
            </BoxPartners>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

